<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách phát sinh lương'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <b-dropdown
              size="sm"
              right
              split
              variant="primary"
              @click="importExcel()"
              class="ml-4"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span> <i class="flaticon2-add-1"></i>Tạo mới</span>
                </div>
              </template>
              <b-dropdown-item @click="importExcel()">
                <i class="far fa-file-excel mr-2"></i>
                <span>Nhập file excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col md="3">
            <date-picker
              placeholder="Bảng lương tháng"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="apiParam.salaryMonth"
            ></date-picker>
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="apiParam.type"
              :options="listType"
              value-field="id"
              text-field="name"
              class="select-style"
            >
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="7">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
          <b-col class="pr-0">
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                SL mỗi trang
              </p>
            </div>
          </b-col>
          <b-col md="3" class="pr-0 mt-3 ml-4">
            <b-form-radio-group
              v-model="pageLimit"
              :options="ippOptions"
            ></b-form-radio-group>
          </b-col>
          <b-col class="pl-0">
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                {{ fromNo }} - {{ toNo }} /
                {{ convertPrice(totalRow) }}
              </p>
            </div>
          </b-col>
        </b-row>
        <b-table
          :items="listItem"
          :fields="fields"
          hover
          bordered
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="6"></vcl-table>
          </template>
          <template v-slot:head(selected)>
            <span>
              <b-form-checkbox
                v-model="checkAllPro"
                size="lg"
                @change="checkAll"
              ></b-form-checkbox>
            </span>
          </template>
          <!-- check bõ -->
          <template v-slot:cell(selected)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                size="lg"
                v-model="row.item.selected"
                @change="clickIndex"
              ></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(stt)="row">
            <div class="d-flex justify-content-center w-6">
              <span v-text="row.item.stt"></span>
            </div>
          </template>
          <template v-slot:cell(amount)="row">
            <div style="text-align: end">
              <span v-text="convertPrice(row.item.amount)"></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-show="checkPermission('SALARY_REPORT')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ convertPrice(totalRow) }}
            </p>
          </b-col>
          <div class="d-flex justify-content-end">
            <b-col>
              <b-pagination-nav
                v-show="numberOfPage >= 2"
                class="customPagination"
                :link-gen="linkGen"
                :number-of-pages="numberOfPage"
                use-router
                @change="fetchData"
                align="right"
                first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                prev-class="page-item-prev btn btn-icon btn-sm mr-1 my-1"
                next-class="page-item-next btn btn-icon btn-sm mr-1 my-1"
                last-class="page-item-last btn btn-icon btn-sm my-1"
                page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
              ></b-pagination-nav>
            </b-col>
          </div>
        </b-row>
        <SalaryIncurredConfigModal
          ref="modal-salary-incurred-import"
          v-on:onRefresh="fetchData"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.icon {
  padding-top: 7px;
}

.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}
</style>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '../../../utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import {
  formatDate,
  makeToastFaile,
  convertPrice,
  makeToastSuccess,
} from '@/utils/common';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import SalaryIncurredConfigModal from '../../components/salary-incrurred-configs/SalaryIncurredConfigModal';

export default {
  mixins: [validationMixin],
  data() {
    return {
      fields: [
        {
          key: 'stt',
          label: 'STT',
          thStyle: { textAlign: 'center', fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'salaryMonth',
          label: 'Tháng lương',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'employeeCode',
          label: 'Mã nhân viên',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'employeeName',
          label: 'Tên nhân viên',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'typeName',
          label: 'Loại phát sinh',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'amount',
          label: 'Số tiền',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      listItem: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: 1,
      count: 1,
      listCate: [],
      totalRow: 0,
      onLoading: false,
      numberOfPages: 1,
      fromNo: 0,
      toNo: 0,
      pageLimit: 10,
      ippOptions: [
        { text: '10', value: '10' },
        { text: '30', value: '30' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
      ],
      apiParam: {
        salaryMonth: '',
        type: null,
      },
      dpConfigs: {
        date: {
          format: 'MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      listType: [
        {
          id: null,
          name: 'Chọn loại phát sinh',
        },
        {
          id: 1,
          name: 'Phát sinh cộng',
        },
        {
          id: 2,
          name: 'Phát sinh trừ',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    SalaryIncurredConfigModal,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phát sinh lương', route: 'salary-incurred-configs' },
      { title: 'Danh sách phát sinh lương' },
    ]);
    this.fetchData();
  },
  methods: {
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-salary-incurred-configs',
      });
      this.fetchData();
    },
    fetchData: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const month = this.apiParam.salaryMonth
        ? moment(this.apiParam.salaryMonth, 'MM/yyyy').toDate()
        : null;
      const salaryMonth = month ? formatDate(month, 'YYYY-MM-DD') : null;
      const param = {
        page: this.page,
        limit: this.pageLimit,
        salaryMonth: salaryMonth,
        type: this.apiParam.type,
      };
      const paramQuery = {
        params: param,
      };
      this.onLoading = true;
      ApiService.query('salary-incurreds', paramQuery)
        .then(async ({ data }) => {
          if (this.page === 1) {
            this.count = 1;
          } else {
            this.count = 1 + (this.page - 1) * this.pageLimit;
          }
          this.totalRow = data.data.total;
          this.numberOfPage = data.data.pages;
          this.fromNo = (this.page - 1) * this.pageLimit + 1;
          this.toNo = this.pageLimit * this.page;
          if (this.toNo > this.totalItem) {
            this.toNo = this.totalItem;
          }
          this.listItem = [];
          data.data.dataset.forEach((element) => {
            const item = {
              ...element,
              stt: this.count,
            };
            this.listItem.push(item);
            this.count++;
          });
          this.onLoading = false;
        })
        .catch((err) => {
          this.onLoading = false;
          throw new Error(err);
        });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    deleteItem: async function (item) {
      ApiService.delete(`salary-incurreds/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          makeToastSuccess(data.message);
          this.fetchData();
        } else {
          makeToastFaile(data.message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phát sinh lương!',
        text: 'Bạn có chắc muốn xóa phát sinh lương này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    convertPrice,
    importExcel: function () {
      this.$bvModal.show('modal-salary-incurred-import');
    },
  },
};
</script>
